<script>
	import { router } from '@/service/router.js'
</script>

<div class="card mb-3">
	<div class="card-body">
		<p>
			It looks like your village hasn't been set up all the way.
		</p>
		<p class="mb-1">
			Head over to
			<a href={router.makePath('app.village')}>Village</a>
			to finish setting up.
		</p>
	</div>
</div>
