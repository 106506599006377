const PREFIX = 'villagesignal'

export const get = key => {
	const value = globalThis.localStorage.getItem(`${PREFIX}|${key}`)
	if (value) return JSON.parse(value)
}

export const set = (key, value) => {
	globalThis.localStorage.setItem(`${PREFIX}|${key}`, JSON.stringify(value))
}
