<script>
	import ErrorAlert from '@/lib/ErrorAlert.svelte'
	import { router } from '@/service/router.js'
	import { post } from '@/service/api.js'

	const fields = [
		{
			label: 'Name',
			key: 'name',
		},
		{
			label: 'Email',
			key: 'email',
		},
		{
			label: 'Phone',
			key: 'phone',
		},
	]

	let form = {
		name: '',
		email: '',
		phone: '',
	}
	let saving
	let success
	let error
	const save = () => {
		success = null
		error = null
		saving = true
		post('/api/people', { body: form })
			.then(() => {
				success = true
			})
			.catch(e => {
				error = e.body.error
				saving = false
			})
	}
	const reset = () => {
		success = null
		saving = null
		form = {
			name: '',
			email: '',
			phone: '',
		}
		try {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		} catch (ignore) {
			//
		}
	}
</script>

<div class="container">
	<h1>Add a Person</h1>
	<hr>
	{#each fields as { label, key }}
		<div class="row align-items-center mb-3">
			<div class="col-3 text-end">
				<label for={key} class="form-label">
					{label}
				</label>
			</div>
			<div class="col-7">
				<input
					disabled={saving || success}
					type="text"
					class="form-control"
					id={key}
					bind:value={form[key]}
					on:input={() => { success = null }}
				>
			</div>
		</div>
	{/each}
	<p>
		(You don't need a phone <em>and</em> an email,
		but you need at least one.)
	</p>
	<div class="alert alert-info">
		Manually adding someone from your community can be
		a great way to help someone connect, but make sure
		you have their permission first!
	</div>
	{#if success}
		<p>Success!</p>
		<p>
			You can
			<a href={router.makePath('app.addPerson')} on:click|preventDefault={reset}>add another person</a>
			or go to
			<a href={router.makePath('app.messages')}>messages</a>,
			or close the window if you are done for now.
		</p>
	{:else}
		<button
			class="btn btn-primary"
			disabled={saving || !form.name || (!form.email && !form.phone)}
			on:click={save}
		>
			Add {form.name || 'Person'}
		</button>
	{/if}
	{#if error}
		<ErrorAlert {error} message="An error happened while adding the person." />
	{/if}
</div>
