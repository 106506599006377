<script>
	import { router } from '@/service/router.js'

	export let messageCount
	export let peopleCount
</script>

<div class="card mb-3">
	<div class="card-body">
		<p>
			To send
			{#if messageCount}
				a message
			{:else}
				your first message
			{/if}
			you'll need to add at least one person.
		</p>
		<p class="mb-1">
			Head over to <a href={router.makePath('app.people')}>People</a> to add
			{#if peopleCount}
				a person.
			{:else}
				your first person.
			{/if}
			(You can even add yourself, if you want to see how it works first.)
		</p>
	</div>
</div>
