<script>
	import ErrorAlert from '@/lib/ErrorAlert.svelte'
	import { router } from '@/service/router.js'
	import { put } from '@/service/api.js'

	export let person
	export let original

	const fields = [
		{
			label: 'Name',
			key: 'name',
		},
		{
			label: 'Email',
			key: 'email',
		},
		{
			label: 'Phone',
			key: 'phone',
		},
	]

	$: changes = fields.find(({ key }) => (person[key] || '') !== (original[key] || ''))

	let saving
	let success
	let error
	const save = () => {
		saving = true
		success = null
		put('/api/person', { body: person })
			.then(() => {
				success = true
				saving = false
			})
			.catch(({ body: { error: e } }) => {
				error = e
				saving = false
			})
	}
</script>

<div class="container">
	{#if !person}
		<div class="alert alert-warning">
			There wasn't a person here with that identifier. Try going to the
			<a href={router.makePath('app.people')}>People list</a> and
			searching there?
		</div>
	{:else}
		<h1>
			<a href={router.makePath('app.people')}>People</a>
			/
			Edit Person
		</h1>
		<hr>
		{#each fields as { label, key }}
			<div class="row align-items-center mb-3">
				<div class="col-3 text-end">
					<label for={key} class="form-label">
						{label}
					</label>
				</div>
				<div class="col-7">
					<input
						disabled={saving}
						type="text"
						class="form-control"
						id={key}
						bind:value={person[key]}
						on:input={() => { success = null }}
					>
				</div>
			</div>
		{/each}
		<div class="row align-items-center">
			<div class="col-auto">
				<button
					class="btn btn-primary"
					disabled={saving || !changes || (!person.email && !person.phone)}
					on:click={save}
				>
					Save Changes
				</button>
			</div>
			<div class="col">
				{#if !changes && !success}
					<span class="text-muted">No changes to save.</span>
				{:else if success}
					Changes saved.
				{/if}
			</div>
		</div>
		{#if error}
			<ErrorAlert {error} message="An error happened when saving." />
		{/if}
	{/if}
</div>
