<script>
	import { router } from '@/service/router.js'
	import { villageCanSend } from '@/shared/village-can-send.js'
	import Message from './Message.svelte'
	import OnboardingSetupVillage from '@/lib/OnboardingSetupVillage.svelte'
	import OnboardingAddPeople from '@/lib/OnboardingAddPeople.svelte'

	const byDate = (a, b) => b.created.localeCompare(a.created)

	export let village
	export let messages
	export let messageCount
	export let peopleCount

	$: canSend = villageCanSend(village)
</script>

<div class="container">
	{#if !canSend}
		<OnboardingSetupVillage />
	{/if}
	{#if !peopleCount}
		<OnboardingAddPeople {peopleCount} {messageCount} />
	{/if}
	{#if canSend && peopleCount}
		<div class="card mb-3">
			<div class="card-body">
				<div class="row">
					<div class="col">
						{#if messages.length}
							Ready to send a message?
						{:else}
							Ready to send your very first message?
						{/if}
					</div>
					<div class="col-auto">
						<a href={router.makePath('app.sendMessage')} class="btn btn-sm btn-primary">
							Start Here
						</a>
					</div>
				</div>
			</div>
		</div>
	{/if}

	{#if messages.length}
		<h2>Past Messages</h2>
		{#each messages.sort(byDate) as message}
			<Message {message} />
		{/each}
	{/if}
</div>
