<script>
	export let message
	$: created = new Date(message.created)
</script>

<div class="card mb-3">
	<div class="card-body">
		<h5 class="card-title">
			{created.toLocaleDateString(undefined, { dateStyle:'long' })}
			<br>
			{created.toLocaleTimeString(undefined, { timeStyle:'long' })}
			<small class="text-muted">
				to
				{message.people?.length || 0}
				{(message.people?.length > 1 || !message.people?.length) ? 'people' : 'person'}
			</small>
		</h5>
		{message.message}
	</div>
</div>
