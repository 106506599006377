import { get } from '@/service/api.js'
import template from './+page.svelte'
export default {
	template,
	resolve: async (_, { id }) => {
		const { body: { data: person } } = await get(`/api/person?id=${id}`)
		return {
			person,
			original: JSON.parse(JSON.stringify(person)),
		}
	},
}
